<template>
  <div>
    <h1 style="text-align: center;">深圳市美亚实业发展有限公司用户服务协议</h1>
    <p>
      深圳市美亚实业发展有限公司用户服务协议（以下简称“本协议”）是深圳市美亚实业发展有限公司（以下简称“我们”）为用户（以下简称“您”）针对深圳市美亚实业发展有限公司系列在线产品和/或服务所适用的服务协议。
    </p>
    <p>
      本协议构成您使用深圳市美亚实业发展有限公司产品和/或服务的先决条件，请您在使用深圳市美亚实业发展有限公司产品和/或服务前仔细阅读本协议的各项条款。
    </p>
    <h2>
      一、服务说明
    </h2>
    <ul>
      <li>
        本协议下，我们会坚决维护您的合法权益并通过下列方式向您提供深圳市美亚实业发展有限公司产品和/或服务：
        <ul class="indent">
          <li>
            （1）通过深圳市美亚实业发展有限公司官网介绍深圳市美亚实业发展有限公司产品和/或服务并提供相应服务；
          </li>
          <li>
            （2）通过各类客户端提供除深圳市美亚实业发展有限公司官网外的深圳市美亚实业发展有限公司产品和/或服务；
          </li>
          <li>
            （3）与上述两类产品和/或服务相关的任何软件；
          </li>
          <li>
            （4）我们不时为您提供的其他深圳市美亚实业发展有限公司产品和/或服务。
          </li>
        </ul>
      </li>
      <li>
        深圳市美亚实业发展有限公司产品和/或服务的适用对象为已满18周岁的成年用户。未成年人需在监护人陪同和指导下使用深圳市美亚实业发展有限公司产品和/或服务。
      </li>
      <li>
        如果您不同意本政策或其中任何内容，您可立即停止使用我们提供的相关服务。为实现实时控制记录的功能，我们将收集您的以下信息：
        <ul class="indent">
          <li>WLAN信息、设备状态等。</li>
        </ul>
      </li>
    </ul>

    <h2>
      二、用户权利与义务
    </h2>
    <ul>
      <li>
        1.您理解并同意，当您使用我们的服务并将其接入互联网时，您需自行承担因您使用该服务所产生的上行和下行的网络通信及流量费用，上述费用将由网络运营商向您直接收取。
      </li>
      <li>
        2.您理解并同意遵守本协议条款和规则，并及时查看、遵守不时公布和/或更新的各项条款和规则。
      </li>
      <li>
        3.您理解并同意，在使用MPE产品和/或服务过程中，您应遵守中华人民共和国宪法和其他法律法规。
      </li>
      <li>
        4.您理解并同意，在使用过程中，您不得从事以下行为：

        <ul class="indent">
          <li>
            （1）上传、传送、传播包含任何反对宪法所确定的基本原则、危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、破坏民族团结、损害国家荣誉和利益、煽动民族仇恨、民族歧视、破坏民族团结、破坏国家宗教政策、宣扬邪教和封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪、侮辱或者诽谤他人，侵害他人合法权益的等法律、行政法规禁止的内容或其他另人反感的包括但不限于资讯、资料、文字、照片、图形、信息或其他资料；
          </li>
          <li>
            （2）上传、传送、传播、向我们公布的邮箱地址发送电子邮件或以其他方式传送无权传送或者发布的内容，例如内部资料、机密资料、侵犯任何人的专利、商标、著作权、商业秘密或其他专属权利之内容、广告函件、促销资料、“垃圾邮件”等内容，以及有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序，包括但不限于资讯、资料、文字、照片、图形、信息或其他资料；
          </li>
          <li>（3）以任何方式危害未成年人；</li>
          <li>
            （4）冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关；
          </li>
          <li>
            （5）跟踪或以其他方式骚扰他人，非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的行为；
          </li>
          <li>
            （6）向他人提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具，或明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助的行为；
          </li>
          <li>
            （7）故意或非故意违反任何相关的中国法律、法规、规章、条例等其他具有法律效力的规范；
          </li>
        </ul>
      </li>
    </ul>
    <h2>
      三、个人信息保护
    </h2>
    <p>
      我们非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用我们提供的服务时，您同意按照我们公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。我们希望通过隐私权政策向您清楚地介绍我们对您个人信息的处理方式，因此我们建议您完整地阅读隐私权政策，以帮助您更好地保护您的隐私权。
    </p>
    <h2>
      四、协议变更与终止
    </h2>
    <ul>
      <li>
        我们有权根据自身业务发展情况或其他合理原因，随时变更或终止部分或全部产品/服务，我们采取上述行为对您和任何第三方均不承担违约责任。
      </li>
      <li>
        我们将依照法律要求和业界成熟、可靠、安全的方案，为您的提供相应的服务与保障。
      </li>
      <li>
        如您在阅读本协议过程中有任何疑惑或需要咨询其他相关事宜，您可通过联系客服、或发送邮件等方式联系我们，我们会尽快为您做出解答。
      </li>
    </ul>
  </div>
</template>

<script></script>

<style scoped>
h1 {
  margin-top: 1em;
  font-size: 1.4rem;
}
h2 {
  margin-top: 0.5em;
  font-size: 1.2rem;
}
p {
  margin-top: 0.3em;
}
div {
  padding: 0.8rem;
}
* {
  line-height: 1.5;
}
.indent {
  text-indent: 1rem;
}
</style>
